import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import PageLayout from "../components/atoms/Contentful/ContentfulPageLayout"

const ContentfulPage = ({ data }) => {
const page = (data && data.page) || false;
const contentfulPage = (page.blocks && page.blocks[0]) || false;
const contentfulSections = (contentfulPage && contentfulPage.blocks) || false;
const blockAlignment = (contentfulPage && contentfulPage.alignment.alignment) || false;
const blockWidth = (contentfulPage.blockWidth && contentfulPage.blockWidth.blockWidth) || false;
const blockPaddingTopBottom = (contentfulPage.paddingTopBottom && contentfulPage.paddingTopBottom.paddingTopBottom.paddingTopBottom) || false;
const pageName = page.pageName || false;
const pageTitle = page.pageTitle || false;
const metaTitle = page.metaTitle || false;
const seoTitle = page.seoTitle || false;
const metaDescription = page.metaDescription || false;
const metaCanonicalLink = page.metaCanonicalLink || false;
const layoutStyle = (page.logoStyle && page.logoStyle.logoStyle) || false;
const genClass = pageName ? 'contentful-page page-' + pageName.trim().replace('.', '').replace(/[^A-Z0-9]+/ig, "-").toLowerCase() : '';
const breadCrumbs = page.breadcrumbs || false;
const dataHelmet = { pageName, pageTitle, metaTitle, metaDescription, metaCanonicalLink };
const textColour = contentfulPage.textColour || false;


    return (
        <Layout headerstyle={layoutStyle === "white" ? "dark" : ""} varHelmet={dataHelmet} genClass={genClass} layoutStyle={layoutStyle}>
          <Seo 
          title={seoTitle || pageTitle}
          description={metaDescription}
          ogDescription={metaDescription}
          twDescription={metaDescription}
          lang="en_AU"
          ogTitle={metaTitle}
          twTitle={metaTitle}
          image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}
          />
          {contentfulSections
          ?
          contentfulSections.map((section, sectionIndex) => (
            <div data-components 
            data-index={sectionIndex}
            data-alignment={blockAlignment} 
            data-text-color={textColour}
            style={{width: blockWidth}}
            data-last={sectionIndex === (contentfulSections.length - 1) ? true : false}
            >
            <PageLayout 
            pageName={pageName} 
            section={section} 
            sectionIndex={sectionIndex} 
            paddingTopBottom={blockPaddingTopBottom} 
            breadCrumbs={breadCrumbs} 
            />
            </div>
          ))
          : ''
          }
        </Layout>
    );
};

export default ContentfulPage

export const query = graphql`
query ContentfulPageByID($contentful_id: String) {
  page: contentfulPage(contentful_id: {eq: $contentful_id}) {
    pageName
    pageTitle
    seoTitle
    metaCanonicalLink
    metaDescription
    metaTitle    
    metaPreviewImage {
      file {
        url
      }
    }
    breadcrumbs
    logoStyle {
      logoStyle
    }
    blocks {
      textColour
      backgroundColour
      alignment {
        alignment
      }
      blockWidth {
        blockWidth
      }
      paddingTopBottom {
        paddingTopBottom {
          paddingTopBottom
        }
      }
      blocks {
        ... on ContentfulBlockButtons {
          name
          internal {
            type
          }
          buttons {
            name
            buttonText
            deepLink
            url
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
        }
        ... on ContentfulBlockSlides {
          name
          internal {
            type
          }
          blocks {
            name
            blockWidth {
              blockWidth
            }
            alignment {
              alignment
            }
          }
          backgroundColour
          slide {
            name
            heading
            subText {
              childMarkdownRemark {
                html
              }
            }
            image {
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
              imageMaxWidth
            }
            vimeoId
            button {
              buttonText
              deepLink
              name
              url
            }
          }
          buttonName
          buttonUrl
          heading
          slideStyle {
            slideStyle
          }
          slideToShow {
            slideToShow
          }
          textColour {
            textColour {
              textColour
            }
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          textAlignment {
            textAlignment
          }
        }
        ... on ContentfulBlockSingleCard {
          name
          internal {
            type
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          backgroundColour
          vimeoId
          content {
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            heading
            headingSize {
              headingSize
            }
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            textAlignment {
              textAlignment
            }
            textColour
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        ... on ContentfulBlockDoubleCards {
          name
          internal {
            type
          }
          backgroundColour
          className
          textColour {
            textColour {
              textColour
            }
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          blocks {
            alignment {
              alignment
            }
            blockWidth {
              blockWidth
            }
            backgroundColour
          }
          
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          paddingTopBottom {
            paddingTopBottom {
              paddingTopBottom
            }
          }
          blockAlignment {
            blockAlignment
          }
          mobileRevert
          contentLeft {
            heading
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
          contentRight {
            heading
            childContentfulBlockContentContentTextNode {
              childMarkdownRemark {
                html
              }
            }
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            name
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
            blockAlignment {
              blockAlignment
            }
            backgroundImage {
              altText
              imageMaxWidth
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            headingSize {
              headingSize
            }
            textAlignment {
              textAlignment
            }
            textColour
            imagePosition {
              imagePosition
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            backgroundColour
          }
        }
        ... on ContentfulBlockContent {
          name
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          blockAlignment {
            blockAlignment
          }
          buttonName
          buttonUrl
          buttonStyle {
            buttonStyle
          }
          heading
          headingSize {
            headingSize
          }
          imagePosition {
            imagePosition
          }
          imageHoverAnimation {
            imageHoverAnimation
          }
          backgroundImage {
            altText
            imageMaxWidth
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          textAlignment {
            textAlignment
          }
          imagePosition {
            imagePosition
          }
          internal {
            type
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulBlockAniParallaxScrolling {
          name
          heading
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          internal {
            type
          }
          backgroundColour
          backgroundImage {
            imageMaxWidth
            altText
            desktopImage {
              gatsbyImageData
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData
              file {
                url
              }
            }
          }
          textColour {
            textColour {
              textColour
            }
          }
          style {
            style
          }
          stickyContent {
            headingSize {
              headingSize
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            imagePosition {
              imagePosition
            }
            name
            textAlignment {
              textAlignment
            }
            textColour
            heading
            className
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
          scrollingContents {
            headingSize {
              headingSize
            }
            imageHoverAnimation {
              imageHoverAnimation
            }
            imagePosition {
              imagePosition
            }
            name
            textAlignment {
              textAlignment
            }
            textColour
            heading
            className
            buttonName
            buttonUrl
            buttonStyle {
              buttonStyle
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            backgroundColour
            backgroundImage {
              imageMaxWidth
              altText
              desktopImage {
                gatsbyImageData
                file {
                  url
                }
              }
              mobileImage {
                gatsbyImageData
                file {
                  url
                }
              }
            }
            blockAlignment {
              blockAlignment
            }
            blockWidth {
              blockWidth {
                blockWidth
              }
            }
          }
        }
        ... on ContentfulBlockTiles {
          name
          internal {
            type
          }
          blockAlignment {
            blockAlignment
          }
          blockWidth {
            blockWidth {
              blockWidth
            }
          }
          blockStyle {
            blockStyle
          }
          backgroundColour
          textColour {
            textColour {
              textColour
            }
          }
          title
          tile {
            ... on ContentfulBlockContent {
              name
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              buttonName
              buttonUrl
              buttonStyle {
                buttonStyle
              }
              heading
              headingSize {
                headingSize
              }
              imagePosition {
                imagePosition
              }
              imageHoverAnimation {
                imageHoverAnimation
              }
              backgroundImage {
                altText
                imageMaxWidth
                desktopImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
                mobileImage {
                  gatsbyImageData
                  file {
                    url
                  }
                }
              }
              textAlignment {
                textAlignment
              }
              content {
                childMarkdownRemark {
                  html
                }
              }
            }
            ... on ContentfulBlockDoubleCards {
              name
              internal {
                type
              }
              backgroundColour
              className
              textColour {
                textColour {
                  textColour
                }
              }
              blocks {
                alignment {
                  alignment
                }
                blockWidth {
                  blockWidth
                }
              }
              blockWidth {
                blockWidth {
                  blockWidth
                }
              }
              blockAlignment {
                blockAlignment
              }
              mobileRevert
              contentLeft {
                heading
                childContentfulBlockContentContentTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                headingSize {
                  headingSize
                }
                textAlignment {
                  textAlignment
                }
                textColour
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
              }
              contentRight {
                heading
                childContentfulBlockContentContentTextNode {
                  childMarkdownRemark {
                    html
                  }
                }
                buttonName
                buttonUrl
                buttonStyle {
                  buttonStyle
                }
                name
                blockWidth {
                  blockWidth {
                    blockWidth
                  }
                }
                backgroundImage {
                  altText
                  imageMaxWidth
                  desktopImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                  mobileImage {
                    gatsbyImageData
                    file {
                      url
                    }
                  }
                }
                headingSize {
                  headingSize
                }
                textAlignment {
                  textAlignment
                }
                textColour
                imagePosition {
                  imagePosition
                }
                imageHoverAnimation {
                  imageHoverAnimation
                }
              }
            }
          }
        }
      }
    }
  }
}
`